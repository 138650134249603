import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Editor from '../components/Editor';
import queryString from 'query-string';
import { navigate } from 'gatsby'
import styles from "../components/header.module.css"
import { CircularProgress } from '@material-ui/core';
import { isUserApproved, getUser, approveUser } from '../controllers/nonUkResidents/hcp/api';

class ApproveUser extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isApproved: false,
            user: null,
            username: null,
            approveLoading: false,
            setRandomCredentials: true,
            setCustomCredentials: false,
            usernameToSet: "",
            passwordToSet: "",
            wasValidated: false,
            validationError: null,
            approvalSuccessful: false
        }
    }

    async componentDidMount(){
        let {user: username = null} = queryString.parse(this.props.location.search || "");

        if(!username){
            navigate("/");
            return;
        }

        let user = await getUser(username);

        let loadingState = {loading: user ? true : false};

        this.setState({user, username, ...loadingState}, async () => {
            if(!user){
                return;
            }

            let isApproved = await isUserApproved(username);
            this.setState({isApproved, loading: false, usernameToSet: this.getUserEmail()});
        });
    }

    approveUser = async () => {
        const {username, approveLoading, setCustomCredentials, usernameToSet, passwordToSet} = this.state;

        if(approveLoading){
            return;
        }

        if(setCustomCredentials){
            if(usernameToSet.length < 1 || passwordToSet.length < 1){
                this.setState({wasValidated: true, validationError: {message: "Please fill in all required fields"}})
                return;
            }
            if(usernameToSet.length < 4){
                this.setState({wasValidated: true, validationError: {message: "Username should be at least 4 characters"}})
                return;
            }
            if(passwordToSet.length < 6){
                this.setState({wasValidated: true, validationError: {message: "Password should be at least 6 characters"}})
                return;
            }
        }

        this.setState({validationError: null, approveLoading: true}, async () => {

            let customCredentials = null;

            if(setCustomCredentials){
                customCredentials = {
                    usernameToSet, passwordToSet
                }
            }

            let result = await approveUser(username, customCredentials);

            this.setState({wasValidated: false, approveLoading: false, approvalSuccessful: result});
        });
    }

    goToHome = () => {
        navigate("/")
    }

    getUserDetails = () => {
        const {user} = this.state;

        if(!user) return "";

        let firstName = user?.UserAttributes?.find(attribute => attribute.Name === "custom:firstName")?.Value;
        let lastName = user?.UserAttributes?.find(attribute => attribute.Name === "custom:lastName")?.Value;
        let email = user?.UserAttributes?.find(attribute => attribute.Name === "email")?.Value;

        return `${firstName || ""} ${lastName || ""} ${email ? "("+email+")" : ""}`
    }

    getUserEmail = () => {
        const {user} = this.state;
        return user?.UserAttributes?.find(attribute => attribute.Name === "email")?.Value || "";
    }

    onRadioChange = (e) => {
        const {setCustomCredentials, setRandomCredentials} = this.state;

        if(e.target.name === "setRandomCredentials"){
            if(!setRandomCredentials){
                this.setState({setRandomCredentials: true, setCustomCredentials: false})
            }
        }else{
            if(!setCustomCredentials){
                this.setState({setRandomCredentials: false, setCustomCredentials: true})
            }
        }
    }

    onTextChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    render(){
        const {loading, isApproved, user, approveLoading, setRandomCredentials, setCustomCredentials, usernameToSet, passwordToSet, wasValidated, validationError, approvalSuccessful} = this.state;

        if(loading){
            return (
                <Layout>
                    <SEO title={"Approve User"} />
                    <div className={styles.landing_logo}>
                        <div className="container">
                            <span className={styles.logo}></span>
                        </div>
                    </div>
                    <div className="container" style={{minHeight:"calc(100vh - 317px"}}>
                        <div className="row">
                            <div className="col-12" style={{marginTop: 50}}>
                            <span>
                                Please wait <CircularProgress size={18} className="ml-1" />
                            </span>
                            </div>
                        </div>
                    </div>
                </Layout>
            )
        }

        if(!user){
            return (
                <Layout>
                    <SEO title={"Approve User"} />
                    <div className={styles.landing_logo}>
                        <div className="container">
                            <span className={styles.logo}></span>
                        </div>
                    </div>
                    <div className="container" style={{minHeight:"calc(100vh - 317px"}}>
                        <div className="row">
                            <div className="col-12" style={{marginTop: 50}}>
                            <p>No such user exists.</p>
                            <button className="btn" onClick={this.goToHome}>
                                Go Back To Homepage
                            </button>
                            </div>
                        </div>
                    </div>
                </Layout>
            )
        }

        if(isApproved){
            return (
                <Layout>
                    <SEO title={"Approve User"} />
                    <div className={styles.landing_logo}>
                        <div className="container">
                            <span className={styles.logo}></span>
                        </div>
                    </div>
                    <div className="container" style={{minHeight:"calc(100vh - 317px"}}>
                        <div className="row">
                            <div className="col-12" style={{marginTop: 50}}>
                            <div>
                                <p>The user {this.getUserDetails()} is already approved</p>
                                <button className="btn" onClick={this.goToHome}>
                                    Go Back To Homepage
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            )
        }

        return (
            <Layout>
                <SEO title={"Approve User"} />
                <div className={styles.landing_logo}>
                    <div className="container">
                        <span className={styles.logo}></span>
                    </div>
                </div>
                <div className="container" style={{minHeight:"calc(100vh - 317px"}}>
                    <div className="row">
                        <div className="col-12" style={{marginTop: 50}}>
                        {approvalSuccessful ? (
                            <div>
                                <p>User {this.getUserDetails()} has been approved successfully. They have received email instructions with their new login credentials.</p>
                                <button className="btn" onClick={this.goToHome}>
                                    Go Back To Homepage
                                </button>
                            </div>
                        ) : (
                            <div>
                                <p>Approve user {this.getUserDetails()}</p>

                                <Editor type={"radio"} name={"setRandomCredentials"} value={setRandomCredentials} value={setRandomCredentials} checked={setRandomCredentials} onChange={this.onRadioChange}  label={<span>Assign random password</span>} />

                                <Editor type={"radio"} name={"setCustomCredentials"} value={setCustomCredentials} value={setCustomCredentials} checked={setCustomCredentials} onChange={this.onRadioChange}  label={<span>Create custom password</span>} />

                                {setCustomCredentials && (
                                    <div>
                                        <Editor type={"text"} name={"usernameToSet"} label={"Username"} value={this.getUserEmail()} required={true} onChange={() => {}} variant={"outlined"} error={wasValidated && usernameToSet.length < 4} disabled={true} />

                                        <Editor type={"text"} name={"passwordToSet"} label={"Password"} value={passwordToSet} required={true} onChange={this.onTextChange} variant={"outlined"} error={wasValidated && passwordToSet.length < 6} />

                                        {validationError && (
                                            <small className="text-danger">{validationError.message}</small>
                                        )}

                                        <br />
                                    </div>
                                )}

                                <br />

                                <button className="btn" onClick={this.approveUser}>
                                    {approveLoading ? <CircularProgress size={18} /> : "Approve User"}
                                </button>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default ApproveUser;